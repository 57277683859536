import { Link } from "react-router-dom";
import {FaEllipsisV,FaLinkedinIn,FaDownload} from 'react-icons/fa';
import {useState, useEffect} from 'react';
import api from '../../services/api';
import { i18n } from "../../translate/i18n";
import Fancybox from "../../plugins/Fancybox";
import Scroll from "../../plugins/SmothScroll";
import OutsideClickHandler from 'react-outside-click-handler';
import { Line } from "rc-progress";
import ChangingProgressProvider from '../../plugins/ChangingProgressProvider';
import {
    CircularProgressbar
  } from 'react-circular-progressbar';


export default function Header(){
    const [user,setUser] = useState([]);
    const [skills, setSkills] = useState([]);
    const [langSkills, setLangSkills] = useState([]);
    const [headerOpen,setHeaderOpen] = useState(0);
    const I18N_STORAGE_KEY = 'i18nextLng';
    const [atualLang, setAtualLang] = useState(localStorage.getItem(I18N_STORAGE_KEY));

    function openHeader(e){
        e.preventDefault();
        if(headerOpen===1){
            setHeaderOpen(0);
            document.querySelector('#artcontent').classList.remove('art-active');
        }
        else if(headerOpen===0){
            setHeaderOpen(1);
            document.querySelector('#artcontent').classList.add('art-active');
        }
    }

    function closeHeaderBar(){
        if(headerOpen===1){
            setHeaderOpen(0);
            document.querySelector('#artcontent').classList.remove('art-active');
        }
        
    }
    
    useEffect(()=>{

        async function loadUserData(){
            const response = await api.get(`About/loadAbout/`,{ params:{ lang:atualLang}});
            setUser(response.data.data.about);
            setSkills(response.data.data.skills);
            setLangSkills(response.data.data.langskill);
        }

        loadUserData();
    },[atualLang]);
    return(
        
        <div className={headerOpen===1 ? "art-info-bar art-active" : "art-info-bar"}>
            <OutsideClickHandler onOutsideClick={() => {
            closeHeaderBar()
          }}>
          <div className="art-info-bar-frame">
            <div className="art-info-bar-header">
              <a className="art-info-bar-btn" href="#." onClick={openHeader}>
                <FaEllipsisV size={14} />
              </a>
            </div>

            <div className="art-header">
              <div className="art-avatar">
              <Fancybox>
                <a data-fancybox="avatar" href={user.image} className="art-avatar-curtain">
                  <img src={user.image} alt="avatar"/>
                  <i className="fas fa-expand"></i>
                </a>
                </Fancybox>
                <div className="art-lamp-light">
                  <div className="art-available-lamp"></div>
                </div>
              </div>

              <h5 className="art-name mb-10"><Link to="/">Paulo Garcia</Link></h5>
              <div className="art-sm-text">Graphic/Web Designer <br/>WebDeveloper</div>
            </div>
            <Scroll/>
            <div id="scrollbar2" className="art-scroll-frame" data-scroll>
              <div className="art-table p-15-15">
                <ul>
                  <li>
                    <h6>{i18n.t('header.country')}:</h6><span>{user.country}</span>
                  </li>
                  <li>
                    <h6>{i18n.t('header.city')}:</h6><span>{user.city}</span>
                  </li>
                  <li>
                    <h6>{i18n.t('header.age')}:</h6><span>{user.age}</span>
                  </li>
                </ul>
              </div>
              <div className="art-ls-divider"></div>
              <div className="art-lang-skills p-30-15">
                {
                    langSkills.map((lgskill)=>{
                        let percentage = lgskill.skill;
                        return(
                            <div className="art-lang-skills-item" key={lgskill.name}>
                                <ChangingProgressProvider values={[percentage]}>
                                {percentage => (
                                    <CircularProgressbar value={percentage} strokeWidth={5} text={`${percentage}%`} className="art-cirkle-progress" />
                                )}
                                </ChangingProgressProvider> 
                                <h6>{lgskill.name}</h6>
                            </div>
                        );
                    })
                }
                
              </div>
              <div className="art-ls-divider"></div>
              <div className="art-hard-skills p-30-15">
                {
                    skills.map((askill)=>{
                        if(askill.type==='skill'){
                            return(
                            <div className="art-hard-skills-item" key={askill.name}>
                                <div className="art-skill-heading">
                                  <h6>{askill.name}</h6>
                                  <span>{Number(askill.skill)}%</span>
                                </div>
                                <div className="art-line-progress">
                                  <Line percent={askill.skill} strokeWidth={1} strokeColor="#FFC107" />
                                </div>
                              </div> 
                            )
                        }
                    })
                }
              </div>
              <div className="art-ls-divider"></div>
              <ul className="art-knowledge-list p-15-0">
              {
                    skills.map((skill)=>{
                        if(skill.type === 'other'){
                            return(
                                <li key={skill.name}>{skill.name}</li>
                            )
                        }
                    })
                }
              </ul>
              <div className="art-ls-divider"></div>
              <div className="art-links-frame p-15-15">
                <a href="https://dragonbox.me/download.php?link=resume-paulo-garcia-black.pdf" target="_blank" className="art-link">{i18n.t('header.download')} <FaDownload size={9}/></a>

              </div>

            </div>

            <div className="art-ls-social">
                <a href="https://www.linkedin.com/in/paulo-a-m-garcia/" target="_blank" rel="noreferrer">
                  <FaLinkedinIn color="#fff" size={14} />
                </a>
            </div>

          </div>
          </OutsideClickHandler>
        </div>

    );
}