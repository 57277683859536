import Rotas from './Rotas';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/style.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


function App() {
    const I18N_STORAGE_KEY = 'i18nextLng';
    const atualLang = localStorage.getItem(I18N_STORAGE_KEY);
  return(
    <GoogleReCaptchaProvider
    reCaptchaKey="6LdiB0MkAAAAAAgb0LB2YtVg0XehVp0fuVUlddz_"
    language={atualLang}
    useRecaptchaNet="false"
    useEnterprise="false"
    scriptProps={{
      async: false, 
      defer: false, 
      appendTo: 'head', 
      nonce: undefined 
    }}
  >
    <div className='art-app'>
      <div className='art-mobile-top-bar'></div>
      <div className='art-app-wrapper'>
        <div className="art-app-container">
          <ToastContainer autoClose={3000} position="top-center" hideProgressBar theme="colored" />
          <Rotas/>
        </div>
      </div>
    </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
