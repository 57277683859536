import { Link } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
export default function Menu(){
    
    const I18N_STORAGE_KEY = 'i18nextLng';
    const [atualLang, setAtualLang] = useState(localStorage.getItem(I18N_STORAGE_KEY));
    const [menuselected, setMenuselected] = useState(0);
    const [menuOpen,setMenuOpen] = useState(0);
    const [pathname,setPathname] = useState('');
    const [pathlink,setPathlink] = useState('');
    const tpathLink = window.location.pathname;
    
    useEffect(()=>{
       
        if(tpathLink==="/"){
            setPathname(i18n.t('menu.home'));
            setMenuselected(0);
        }
        if(tpathLink==="/about"){
            setPathname(i18n.t('menu.about'));
            setMenuselected(1);
        }
        if(tpathLink==="/history"){
            setPathname(i18n.t('menu.history'));
            setMenuselected(2);
        }
        if(tpathLink==="/portfolio"){
            setPathname(i18n.t('menu.portfolio'));
            setMenuselected(3);
        }
        if(tpathLink==="/contact"){
            setPathname(i18n.t('menu.contact'));
            setMenuselected(4);
        }
        setPathlink(tpathLink);

    },[tpathLink]);

    function refreshPage() {
        window.location.reload(false);
      }
    function handleChangeLang(e){
        setAtualLang(e);
        localStorage.setItem(I18N_STORAGE_KEY,e);
        refreshPage();
    }
    function menuChange(e){
        setMenuselected(e);
    }
    function openMenu(){
        
        if(menuOpen===1){
            setMenuOpen(0);
            document.querySelector('#artcontent').classList.remove('art-active');
        }
        else if(menuOpen===0){
            setMenuOpen(1);
            document.querySelector('#artcontent').classList.add('art-active');
        }
    }
    function closeMenuBar(){
        const elm = document.querySelector('#artmenubar');
        const elmd = document.querySelector('#artmenubarbtn');
        if(menuOpen===1){
            elm.classList.remove('art-active');
            elmd.classList.remove('art-active');
            document.querySelector('#artcontent').classList.remove('art-active');
            setMenuOpen(0);
        }
        
    }
    
    return(
        
        <div id="artmenubar" className={menuOpen===1 ? "art-menu-bar art-active" : "art-menu-bar"}>
            <OutsideClickHandler onOutsideClick={() => {
            closeMenuBar()
          }}>
            <div className="art-menu-bar-frame">
                <div className="art-menu-bar-header">
                    <a id="artmenubarbtn" className={menuOpen===1 ? "art-menu-bar-btn art-active" : "art-menu-bar-btn"} href="#." onClick={openMenu}>
                        <span></span>
                    </a>
                </div>
                <div className="art-current-page"><Link to={pathlink}>{pathname}</Link></div>
                <div className="art-scroll-frame">
                    <nav id="swupMenu">
                        <ul className="main-menu">
                            <li className={menuselected===0 ? "menu-item current-menu-item" : "menu-item"}><Link to="/" onClick={()=>{menuChange(0);closeMenuBar();}}>{i18n.t('menu.home')}</Link></li>
                            <li className={menuselected===1 ? "menu-item current-menu-item" : "menu-item"}><Link to="/about" onClick={()=>{menuChange(1);closeMenuBar();}}>{i18n.t('menu.about')}</Link></li>
                            <li className={menuselected===2 ? "menu-item current-menu-item" : "menu-item"}><Link to="/history" onClick={()=>{menuChange(2);closeMenuBar();}}>{i18n.t('menu.history')}</Link></li>
                            <li className={menuselected===3 ? "menu-item current-menu-item" : "menu-item"}><Link to="/portfolio" onClick={()=>{menuChange(3);closeMenuBar();}}>{i18n.t('menu.portfolio')}</Link></li>
                            <li className={menuselected===4 ? "menu-item current-menu-item" : "menu-item"}><Link to="/contact" onClick={()=>{menuChange(4);closeMenuBar();}}>{i18n.t('menu.contact')}</Link></li>
                        </ul>
                    </nav>
                    <ul className="art-language-change">
                        <li className={atualLang==='pt-PT' ? 'art-active-lang' : ''}><a href="#." onClick={()=>{handleChangeLang('pt-PT')}}>PT</a></li>
                        <li className={atualLang==='en-US' ? 'art-active-lang' : ''}><a href="#." onClick={()=>{handleChangeLang('en-US')}}>EN</a></li>
                    </ul>
                </div>
            </div>
            </OutsideClickHandler>
        </div>
        
    )
}