import background from '../../assets/images/dragonb-bg.jpg';
import { i18n } from "../../translate/i18n";
import ReactTypingEffect from 'react-typing-effect';
import useDocumentTitle from '../../components/Documenttitle';
import { Link } from 'react-router-dom';
import Scroll from '../../plugins/SmothScroll';
import api from '../../services/api';
import { motion } from 'framer-motion';
import { animationOne } from '../../plugins/animations';
import CountUp from 'react-countup';
import { useState, useEffect } from 'react';
export default function Home(){
    useDocumentTitle('Paulo Augusto Matos Garcia | Designer & Developer');
    const [servicos,setServicos] = useState([]);
    const I18N_STORAGE_KEY = 'i18nextLng';
    const atualLang = localStorage.getItem(I18N_STORAGE_KEY);

    useEffect(()=>{
        async function loadServices(){
            const services = await api.get('About/loadServices/',{params:{lang:atualLang}});
            setServicos(services.data.data.services);
        }
        loadServices();
    },[atualLang]);
    return(
        <div id="artcontent" className="art-content">
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: `url(${background})`}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <motion.div initial='out' animate='in' exit='out' variants={animationOne}>
                <Scroll/>
                <div id="scrollbar" className="art-scroll-frame">
                    <div className="container-fluid">
                        <div className="row p-60-0 p-lg-30-0 p-md-15-0">
                            <div className="col-lg-12">
                                <div className="art-a art-banner" style={{backgroundImage: `url(${background})`}}>
                                    <div className="art-banner-back"></div>
                                    <div className="art-banner-dec"></div>
                                    <div className="art-banner-overlay">
                                        <div className="art-banner-title">
                                            <h1 className="mb-15">{i18n.t('home.bannerTitle')}<br/>{i18n.t('home.bannerTitle2')}</h1>
                                            
                                            <div className="art-lg-text art-code mb-25">&lt;<i>code</i>&gt; {i18n.t('home.static')} <span className="txt-rotate"><ReactTypingEffect
                                                text={[i18n.t('home.one'), i18n.t('home.two'), i18n.t('home.three'), i18n.t('home.four'), i18n.t('home.five'), i18n.t('home.six')]} speed={100} eraseSpeed={10}
                                            /></span>&lt;/<i>code</i>&gt;</div>
                                            <div className="art-buttons-frame">
                                                <Link to="/portfolio" className="art-btn art-btn-md"><span>{i18n.t('home.explore')}</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row p-30-0">

                            <div className="col-md-4 col-6">
                                <div className="art-counter-frame">
                                <div className="art-counter-box">
                                    <CountUp className="art-counter" end={20} duration={3} /><span className="art-counter-plus">+</span>
                                </div>
                                <h6>{i18n.t('home.experience')}</h6>
                                </div>
                            </div>
                            
                            <div className="col-md-4 col-6">
                                <div className="art-counter-frame">
                                <div className="art-counter-box">
                                <CountUp className="art-counter" end={100} duration={3} /><span className="art-counter-plus">+</span>
                                </div>
                                <h6>{i18n.t('home.workstotal')}</h6>
                                </div>
                            </div>

                            <div className="col-md-4 col-12">
                                <div className="art-counter-frame">
                                <div className="art-counter-box">
                                <CountUp className="art-counter" end={40} duration={3} /><span className="art-counter-plus">+</span>
                                </div>
                                <h6>{i18n.t('home.happyCliente')}</h6>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="art-section-title">
                                    <div className="art-title-frame">
                                        <h4>{i18n.t('home.servicesTitle')}</h4>
                                    </div>
                                </div>
                            </div>
                            {
                               servicos.map((serv)=>{
                                return(
                                    <div className="col-lg-4 col-md-6" key={serv.ID}>
                                        <div className="art-a art-service-icon-box">
                                            <div className="art-service-ib-content">
                                                <h5 className="mb-15">{serv.title}</h5>
                                    <div className="mb-15">{serv.description}</div>
                                    <div className="art-buttons-frame"><a href="/contact" className="art-link art-color-link art-w-chevron">{i18n.t('home.knowmore')}</a></div>
                                </div>
                                </div>
                            </div>
                                )
                               }) 
                            }
                        </div>
                    </div>

                    <div className='container-fluid'>
                        <footer>
                            <div>{i18n.t('footer.rights')}</div>
                        </footer>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}