const messages = {
    pt:{
        translations:{
            home:{
                bannerTitle:'Descubra o meu Incrível',
                bannerTitle2:'Espaço de Arte!',
                static:'Crio e desenvolvo ',
                one:'aplicações web em PHP.',
                two:'aplicações Android.',
                three:'design mocups.',
                four:'Branding ou Re-Branding design.',
                five:'videos After Effects.',
                six:'desin / animação 3D',
                explore:'Explore',
                servicesTitle:'Serviços disponíveis', 
                happyCliente:'Clientes satisfeitos',
                experience:'Anos de experiência',
                workstotal:'Projectos desenvolvidos',
                knowmore:'Peça-me mais info.'
            },
            menu:{
                home:'Início',
                about:'Biografia',
                contact:'Contactos',
                portfolio:'Portfolio',
                history:'Curriculo'
            },
            header:{
                country:'País',
                city:'Cidade',
                age:'Idade',
                address:'Morada',
                download:'Descarregar CV',
            },
            footer:{
                rights:'© '+new Date().getFullYear()+' Paulo Garcia, Todos os direitos reservados'
            },
            contact:{
                title:'Informações de Contacto',
                formtitle:'Entre em contacto',
                formname:'Nome',
                formemail:'E-mail',
                formsubject:'Assunto',
                formmessage:'Mensagem',
                formsubmit:'Enviar mensagem',
                sentMsg:{
                    success:'A sua mensagem foi enviada, Obrigado',
                    error:'Ocorreu um erro ao enviar a sua mensagem, por favor tente mais tarde'
                },
                fieldsrequired:'Todos os campos são obrigatórios!',
                titledoc:'Entre em contacto'
            },
            historia:{
                educacao:'Educação & Formação',
                curriculo:'Histórico profissional',
                titledoc:'Histórico profissional & Educação', 
                title:'Histórico profissional & Educação'
            },
            portfolio:{
                all:'Todas as categorias',
                web:'Web Development',
                graph:'Design Gráfico',
                img3D:'Imagens 3D',
                video3D:'Videos 3D',
                titledoc:'Portefólio',
                visit:'Visitar website',
                title:'Alguns projetos desenvolvidos',
                seevideo:'Ver video'
            },
            sobre:{
                about:'Biografia',
                titledoc:'Biografia'
            }
        }
    }
}
export {messages}