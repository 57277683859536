import { useEffect } from "react";
import Scrollbar from 'smooth-scrollbar';
import OverscollPlugin from 'smooth-scrollbar/plugins/overscroll'
const overscrollOptions = {
    enable:true,
    effect:'bounce',
    damping:0.15,
    maxOverscroll:150
}
let options = {
    damping:0.07,
    plugins:{
        overscroll:{
            ...overscrollOptions
        }
    }
}
const Scroll = () => {

    useEffect(()=>{
        Scrollbar.use(OverscollPlugin);
        Scrollbar.init(document.querySelector('#scrollbar2'),options);
        Scrollbar.init(document.querySelector('#scrollbar'),options);
    },[]);
    return null;
}

export default Scroll;