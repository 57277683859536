const messages = {
    en:{
        translations:{
            home:{
                bannerTitle:'Discover my Amazing',
                bannerTitle2:'Art Space!',
                static:'I create and develop ',
                one:'PHP web applications.',
                two:'Android applications.',
                three:'design mocups.',
                four:'Branding or Re-Branding design.',
                five:'After Effects motion videos.',
                six:'3D Desin / animations',
                explore:'Explore',
                servicesTitle:'Available services',
                happyCliente:'Happy Clients',
                experience:'Years Experience',
                workstotal:'Completed Projects',
                knowmore:'Ask me for more'
            },
            menu:{
                home:'Home',
                about:'Biografy',
                contact:'Contacts',
                portfolio:'Portfolio',
                history:'Resume'
            },
            header:{
                country:'Country',
                city:'City',
                age:'Age',
                address:'Street',
                download:'Download CV',
            },
            footer:{
                rights:'© '+new Date().getFullYear()+' Paulo Garcia, All rights reserved'
            },
            contact:{
                title:'Contact Information',
                formtitle:'Get in touch',
                formname:'Name',
                formemail:'E-mail',
                formsubject:'Subject',
                formmessage:'Message',
                formsubmit:'Send Message',
                sentMsg:{
                    success:'Your message has been sent, thank you',
                    error:'There was an error sending your message, please try again later'
                },
                fieldsrequired:'All fields are required!',
                titledoc:'Get in touch'
            },
            historia:{
                educacao:'Education',
                curriculo:'Professional History',
                titledoc:'Professional History & Education',
                title:'Professional History & Education'
            },
            portfolio:{
                all:'All categories',
                web:'Web Development',
                graph:'Graphic Design',
                img3D:'3D Images',
                video3D:'3D Videos',
                titledoc:'My portfolio',
                visit:'Visit website',
                title:'Some developed projects',
                seevideo:'Watch video'
            },
            sobre:{
                about:'Biografy',
                titledoc:'Biografy'
            },

        }
    }
}
export {messages}