import background from '../../assets/images/dragonb-bg.jpg';
import { i18n } from "../../translate/i18n";
import Scroll from '../../plugins/SmothScroll';
import { motion } from 'framer-motion';
import { animationOne } from '../../plugins/animations';
import useDocumentTitle from '../../components/Documenttitle';
import {useState} from 'react';
import { toast } from 'react-toastify';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import api from '../../services/api';
export default function Contact(){
    useDocumentTitle(`Paulo Augusto Matos Garcia | Designer & Developer | ${i18n.t('contact.titledoc')}`);
    const [nome,setNome] = useState('');
    const [mail,setMail] = useState('');
    const [assunto,setAssunto] = useState('');
    const [mensagem,setMensagem] = useState('');
    const position = [40.842361, -8.593820];


   async function handleSendMsg(e){
        e.preventDefault();
        if(nome!=='' && mail!=='' && assunto!=='' && mensagem!==''){
            const form = document.querySelector('form');
            const formData = new FormData(form);
            await api.post('Sendmessage/SendMail/',formData,{
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
            .then((response)=>{
                if(response.data.status===true){
                    toast.success(i18n.t('contact.sentMsg.success'));
                    setNome('');
                    setMail('');
                    setAssunto('');
                    setMensagem('');
                } else {
                    toast.error(i18n.t('contact.sentMsg.error'));
                }
            })
            .catch((error)=>{
                console.log(error);
                toast.error(i18n.t('contact.sentMsg.error'));
            });
        } else {
            toast.error(i18n.t('contact.fieldsrequired'));
        }
    }

    return(
        <div id="artcontent" className="art-content">
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: `url(${background})`}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <motion.div initial='out' animate='in' exit='out' variants={animationOne}>
                <Scroll/>
                <div id="scrollbar" className="art-scroll-frame">
                    <div className='container-fluid'>
                        <div className='row p-30-0'>
                            <div className="col-lg-12">
                                <div className="art-section-title">
                                    <div className="art-title-frame">
                                        <h3>{i18n.t('contact.title')}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mb-15">
                                <MapContainer id="map" center={position} zoom={16} scrollWheelZoom={false}>
                                    <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={position}>
                                    </Marker>
                                </MapContainer>
                            </div>
                            <div className="col-lg-6">
                                <div className="art-a art-card">
                                    <div className="art-table p-15-15">
                                        <ul>
                                            <li>
                                                <h6>{i18n.t('header.country')}:</h6><span>Portugal</span>
                                            </li>
                                            <li>
                                                <h6>{i18n.t('header.city')}:</h6><span>Aveiro, Ovar</span>
                                            </li>
                                            <li>
                                                <h6>{i18n.t('header.address')}:</h6><span>Rua de Real</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="art-a art-card">
                                    <div className="art-table p-15-15">
                                        <ul>
                                            <li>
                                                <h6>Email:</h6><span><a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%70%61%6D%61%74%6F%73%67%61%72%63%69%61%40%67%6D%61%69%6C%2E%63%6F%6D">pamatosgarcia@gmail.com</a></span>
                                            </li>
                                            <li>
                                                <h6>&nbsp;</h6><span>&nbsp;</span>
                                            </li>
                                            <li>
                                                <h6>&nbsp;</h6><span>&nbsp;</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="art-section-title">
                                    <div className="art-title-frame">
                                        <h3>{i18n.t('contact.formtitle')}</h3>
                                    </div>
                                </div>
                                <div className="art-a art-card">
                                    <form id="form" className="art-contact-form" onSubmit={handleSendMsg}>
                                        <div className="art-form-field">
                                        <input id="name" name="name" className="art-input" type="text" value={nome} placeholder={i18n.t('contact.formname')} onChange={(e)=> setNome(e.target.value)}/>
                                        <label htmlFor="name"><i className="fas fa-user"></i></label>
                                        </div>
                                        <div className="art-form-field">
                                        <input id="email" name="email" className="art-input" type="email" value={mail} placeholder={i18n.t('contact.formemail')} onChange={(e)=> setMail(e.target.value)}/>
                                        <label htmlFor="email"><i className="fas fa-at"></i></label>
                                        </div>
                                        <div className="art-form-field">
                                        <input id="assunto" name="assunto" className="art-input" type="text" value={assunto} placeholder={i18n.t('contact.formsubject')} onChange={(e)=> setAssunto(e.target.value)}/>
                                        <label htmlFor="assunto"><i className="fas fa-question"></i></label>
                                        </div>
                                        <div className="art-form-field">
                                        <textarea id="mensagem" name="mensagem" className="art-input" value={mensagem} placeholder={i18n.t('contact.formmessage')} onChange={(e)=> setMensagem(e.target.value)}/>
                                        <label htmlFor="mensagem"><i className="far fa-envelope"></i></label>
                                        </div>
                                        <div className="art-submit-frame">
                                        <button className="art-btn art-btn-md art-submit" type="submit"><span>{i18n.t('contact.formsubmit')}</span></button>
                                        <div className="art-success"><i className="fas fa-check"></i></div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <footer>
                            <div>{i18n.t('footer.rights')}</div>
                        </footer>
                    </div>
                </div>
                </motion.div>
        </div>
    );
}