import {useRef } from 'react';
import useVideoPlayer from '../../plugins/useVideoPlayer';
import { FiX, FiPlay, FiPause, FiVolume2, FiVolumeX } from 'react-icons/fi';
import './modal.css';

export default function Modal({conteudo,close}){
    const videoElement = useRef(null);
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute,
      } = useVideoPlayer(videoElement);
      
    return (
        <div className='modal'>
            <div className='container'>
                <button title="Close" className='close' onClick={ close }>
                    <FiX size={23} color="#fff" />
                </button>
                <h2>{conteudo.nome}</h2>
                <div className="video-wrapper">
                    <video
                        src={conteudo.video}
                        ref={videoElement}
                        poster={conteudo.poster}
                        onTimeUpdate={handleOnTimeUpdate}
                    />
                    <div className="controls">
                        <div className="actions">
                            <button onClick={togglePlay}>
                            {!playerState.isPlaying ? (
                                <FiPlay color="#fff" size={14} />
                            ) : (
                                <FiPause color="#fff" size={14} />
                            )}
                            </button>
                        </div>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={playerState.progress}
                            onChange={(e) => handleVideoProgress(e)}
                        />
                        <select
                            className="velocity"
                            value={playerState.speed}
                            onChange={(e) => handleVideoSpeed(e)}
                        >
                            <option value="0.50">0.50x</option>
                            <option value="1">1x</option>
                            <option value="1.25">1.25x</option>
                            <option value="2">2x</option>
                        </select>
                        <button className="mute-btn" onClick={toggleMute}>
                            {!playerState.isMuted ? (
                            <FiVolume2 color="#fff" size={14}/>
                            ) : (
                            <FiVolumeX color="#fff" size={14}/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}