import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from '../pages/Home';
import About from '../pages/About';
import History from '../pages/History';
import Contact from '../pages/Contact';
import Portfolio from '../pages/Portfolio';
import Header from '../components/Header';
import Menu from '../components/Menu';
export default function Rotas(){
    return(
        <BrowserRouter>
            <Header/>
            <AnimatePresence>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/about" element={<About/>} />
                    <Route path="/history" element={<History/>} />
                    <Route path="/portfolio" element={<Portfolio/>} />
                    <Route path="/contact" element={<Contact/>} />
                </Routes>
            </AnimatePresence>
            <Menu/>
        </BrowserRouter>
    );
}