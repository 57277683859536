import background from '../../assets/images/dragonb-bg.jpg';
import { i18n } from "../../translate/i18n";
import Scroll from '../../plugins/SmothScroll';
import { motion } from 'framer-motion';
import { animationOne } from '../../plugins/animations';
import useDocumentTitle from '../../components/Documenttitle';
import { useEffect, useState } from 'react';
import api from '../../services/api';
export default function History(){
    useDocumentTitle(`Paulo Augusto Matos Garcia | Designer & Developer | ${i18n.t('historia.titledoc')}`);
    const[educacao,setEducacao] = useState([]);
    const[curriculo,setCurriculo] = useState([]);
    const I18N_STORAGE_KEY = 'i18nextLng';
    const atualLang = localStorage.getItem(I18N_STORAGE_KEY);

    useEffect(()=>{
      async function loadHistoryData(){
        const resposta = await api.get('History/getHistory/',{ params:{ lang:atualLang}});
        setCurriculo(resposta.data.dados.cv);
        setEducacao(resposta.data.dados.educ);
      }
      loadHistoryData();
    },[atualLang]);

    return(
        <div id="artcontent" className="art-content">
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: `url(${background})`}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <motion.div initial='out' animate='in' exit='out' variants={animationOne}>
                <Scroll/>
                <div id="scrollbar" className="art-scroll-frame">
                    <div className='container-fluid'>
                        <div className="row p-30-0">
                          <div className="col-lg-12 mb-30">
                            <div className="art-section-title">
                              <div className="art-title-frame">
                                <h3>{i18n.t('historia.title')}</h3>
                              </div>
                            </div>  
                          </div>
                            <div className="col-lg-6">
                                <div className="art-section-title">
                                    <div className="art-title-frame">
                                        <h4>{i18n.t('historia.educacao')}</h4>
                                    </div>
                                </div>
                                {
                                  educacao.map((ed)=>{
                                    return(
                                  <div className="art-timeline art-gallery" key={ed.ID}>
                                    <div className="art-timeline-item">
                                        <div className="art-timeline-mark-light"></div>
                                        <div className="art-timeline-mark"></div>
                                        <div className="art-a art-timeline-content">
                                        <div className="art-card-header">
                                            <div className="art-left-side">
                                            <h5>{ed.company}</h5>
                                            <div className="art-el-suptitle mb-15">{ed.cargo}</div>
                                            </div>
                                            <div className="art-right-side">
                                            <span className="art-date">{ed.data}</span>
                                            </div>
                                        </div>
                                        <p className="display-linebreak" dangerouslySetInnerHTML={{__html:ed.atividade}}></p>
                                        </div>
                                    </div>
                                  </div>
                                    );
                                  })
                                }
                            </div>
                  <div className="col-lg-6">

                    <div className="art-section-title">
                      <div className="art-title-frame">
                        <h4>{i18n.t('historia.curriculo')}</h4>
                      </div>
                    </div>

                    <div className="art-timeline">
                      {
                        curriculo.map( (cv) =>{
                          return(
                            <div className="art-timeline-item" key={cv.ID}>
                              <div className="art-timeline-mark-light"></div>
                              <div className="art-timeline-mark"></div>
                              <div className="art-a art-timeline-content">
                                <div className="art-card-header">
                                  <div className="art-left-side">
                                    <h5>{cv.company}</h5>
                                    <div className="art-el-suptitle mb-15">{cv.cargo}</div>
                                  </div>
                                  <div className="art-right-side">
                                    <span className="art-date">{cv.data}</span>
                                  </div>
                                </div>
                                <p className="display-linebreak" dangerouslySetInnerHTML={{__html: cv.atividade}}></p>
                              </div>
                            </div>
                          )
                        })
                      }
                      </div>
                    </div>

                  </div>
                </div>
                    <div className='container-fluid'>
                        <footer>
                            <div>{i18n.t('footer.rights')}</div>
                        </footer>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}