import background from '../../assets/images/dragonb-bg.jpg';
import { i18n } from "../../translate/i18n";
import Fancybox from "../../plugins/Fancybox";
import Scroll from '../../plugins/SmothScroll';
import { motion } from 'framer-motion';
import { animationOne } from '../../plugins/animations';
import useDocumentTitle from '../../components/Documenttitle';
import api from '../../services/api';
import { useState, useEffect } from 'react';
import Modal from '../../components/Modal';


export default function Portfolio(){
    useDocumentTitle(`Paulo Augusto Matos Garcia | Designer & Developer | ${i18n.t('portfolio.titledoc')}`);
    const [filterKey, setFilterKey] = useState("*");
    const [filtrinhos, setFiltrinhos] = useState([]);
    const [works, setWorks] = useState([]);
    const I18N_STORAGE_KEY = 'i18nextLng';
    const atualLang = localStorage.getItem(I18N_STORAGE_KEY);
    const [showPostModal, setShowPostModal] = useState(false);
    const [detail,setDetail] = useState(null);


      useEffect(()=>{
        async function loadPortolio(){
            const respwork = await api.get('Works/getWorks/',{params:{lang:atualLang}});
            setWorks(respwork.data.dados.works);
            setFiltrinhos(respwork.data.dados.filtros);
        }
        loadPortolio();
      },[atualLang]);

      function handleFilterKeyChange (key){
        setFilterKey(key);
      } 

      function togglePostModal(item){
        setShowPostModal(true); 
        setDetail(item);
      }
      function closeModal(){
        setShowPostModal(false);
        setDetail(null);
      }
    return(
        <div id="artcontent" className="art-content">
            { showPostModal ? <Modal conteudo={detail} close={closeModal}/> : '' }
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: `url(${background})`}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <motion.div initial='out' animate='in' exit='out' variants={animationOne}>
                <Scroll/>
                <div id="scrollbar" className="art-scroll-frame">
                    <div className='container-fluid'>
                        <div className="row p-30-0">
                            <div className="col-lg-12">
                                <div className="art-filter mb-30">
                                <h3 className='mb-30'>{i18n.t('portfolio.title')}</h3>
                                {
                                    filtrinhos.map( (f,index) => {
                                        let catfilter = f.permalink;
                                        return(
                                                <div key={index} className={filterKey === catfilter ? "art-link art-current" : "art-link"}  onClick={() => handleFilterKeyChange(catfilter)}>{f.categoria}</div>
                                        );
                                    })
                                }
                                </div>
                            </div>

                            <div className="art-grid art-grid-3-col art-gallery">
                                {  
                                    works.map((w,index) => {
                                        return(
                                            <div key={index} className={filterKey === w.categoria || filterKey==='*' ? `art-grid-item ${w.categoria}` : `art-grid-item ${w.categoria} hide-item`}>
                                                <Fancybox>
                                                    <a data-fancybox={w.categoria} href={w.img} className="art-a art-portfolio-item-frame art-horizontal">
                                                        <img src={w.img} alt="item"/>
                                                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                                                    </a>
                                                </Fancybox>
                                                <div className="art-item-description">
                                                    <h5 className="mb-15">{w.nome}</h5>
                                                    {w.url ? <a href={w.url} rel="noreferrer" target="_blank" className="art-link art-color-link art-w-chevron">{i18n.t('portfolio.visit')}</a> : ''}
                                                    {w.video ? <button type="button" className='art-link art-color-link art-w-chevron' onClick={() => togglePostModal(w)}>{i18n.t('portfolio.seevideo')}</button> : ''}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className='container-fluid'>
                        <footer>
                            <div>{i18n.t('footer.rights')}</div>
                        </footer>
                    </div>
                </div>
                </motion.div>
                
        </div>
        
    );
}