import background from '../../assets/images/dragonb-bg.jpg';
import { i18n } from "../../translate/i18n";
import Scroll from '../../plugins/SmothScroll';
import { motion } from 'framer-motion';
import api from '../../services/api';
import { animationOne } from '../../plugins/animations';
import useDocumentTitle from '../../components/Documenttitle';
import {useState, useEffect} from 'react';
export default function About(){
    const I18N_STORAGE_KEY = 'i18nextLng';
    const [atualLang, setAtualLang] = useState(localStorage.getItem(I18N_STORAGE_KEY));
    const [aboutme,setAboutme] = useState([]);
    useDocumentTitle(`Paulo Augusto Matos Garcia | Designer & Developer | ${i18n.t('sobre.titledoc')}`);

    useEffect(()=>{

        async function loadAboutData(){
            const responst = await api.get(`About/loadAbout/`,{ params:{ lang:atualLang}});
            setAboutme(responst.data.data.about);
        }
                
        loadAboutData();
    },[atualLang]);

    
    return(
        <div id="artcontent" className="art-content">
            <div className="art-curtain"></div>
            <div className="art-top-bg" style={{backgroundImage: `url(${background})`}}>
                <div className="art-top-bg-overlay"></div>
            </div>
            <motion.div initial='out' animate='in' exit='out' variants={animationOne}>
                <Scroll/>
                <div id="scrollbar" className="art-scroll-frame">
                    <div className='container-fluid'>
                        <div className='row p-30-0'>
                            <div className="col-lg-12">
                                <div className="art-section-title">
                                    <div className="art-title-frame">
                                        <h3>{i18n.t('sobre.about')}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="art-a art-card">
                                    <div className="art-table p-15-15">
                                        <ul>
                                            <li>
                                                <p className="display-linebreak">{aboutme.text}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <footer>
                            <div>{i18n.t('footer.rights')}</div>
                        </footer>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}